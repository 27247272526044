<template>
  <div
    class="modal fade"
    tabindex="-10"
    id="modal_add_pagamento_movimenti_personale"
  >
    <div class="modal-dialog">
      <Form
        @submit="onSubmit"
        :validation-schema="schema"
        @invalid-submit="onInvalidSubmit"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Introduzione pagamenti</h5>
            <!--begin::Close-->
            <div
              class="btn btn-icon btn-sm btn-active-secondary"
              style="border: 0; !important"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="bi bi-x-lg"> </i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body">
            <div class="card-body p-6">
              <form autocomplete="off">
                <div
                  class="alert alert-danger p-6 rounded text-start mb-5 mx-5"
                  role="alert"
                  v-if="alertCampiObb"
                >
                  Compila i campi obbligatori
                </div>
                <div class="ps-5 pe-5" v-if="caricato">
                  <div class="row pb-4">
                    <div class="col-md-4">
                      <label class="fw-bold">Importo*</label>
                      <input
                        type="text"
                        disabled
                        class="form-control"
                        :value="importo.toFixed(2) + ' €'"
                      />
                    </div>
                    <div class="col-md-4">
                      <label class="fw-bold">Tipo pagamento*</label>
                      <Field
                        name="id_Tipo_Pagamento"
                        as="select"
                        class="form-select"
                        v-model="id_Tipo_Pagamento"
                        @change="setPagamentoTipo($event)"
                      >
                        <option value="00" selected>Seleziona</option>
                        <option
                          v-for="pagamento in pagamento_tipo_uso_albo_filtrato_per_importo"
                          :key="pagamento.id"
                          :value="pagamento.id"
                          :id="pagamento.dati_agg"
                        >
                          {{ pagamento.label }}
                        </option>
                      </Field>
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="id_Tipo_Pagamento"
                      />
                    </div>
                    <div class="col-md-4" v-if="pagDatiAggiuntivi">
                      <label class="fw-bold">Data pagamento</label>
                      <Datepicker
                        v-model="dataPagamento"
                        placeholder=""
                        :format="format"
                        :monthChangeOnScroll="false"
                        :enableTimePicker="false"
                        inputClassName="h-28"
                        autoApply
                        locale="it"
                        :maxDate="new Date()"
                        @update:model-value="
                          dataPagamento = fromUTCtoISODate($event)
                        "
                      />
                      <Field
                        name="data_Competenza"
                        type="hidden"
                        class="form-control"
                        v-model="dataPagamento"
                      />
                      <ErrorMessage name="data_Competenza" style="color: red" />
                    </div>
                  </div>
                  <div class="row pb-4" v-if="pagDatiAggiuntivi">
                    <div class="col-md-12">
                      <label class="fw-bold">Banca / Agenzia*</label>
                      <Field
                        name="banca"
                        type="text"
                        v-model="banca"
                        class="form-control"
                        autocomplete="off"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="banca"
                      />
                    </div>
                  </div>
                  <div class="row pb-4" v-if="pagDatiAggiuntivi">
                    <div class="col-md-12">
                      <label class="fw-bold">Riferimenti*</label>
                      <Field
                        name="riferimento"
                        type="text"
                        class="form-control"
                        v-model="riferimento"
                        autocomplete="off"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="riferimento"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="me-3 badge bg-light-danger rounded text-danger fs-6"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="closeModalPagamentoMovimenti"
            >
              Chiudi
            </button>
            <button
              type="button"
              class="badge bg-danger rounded fs-6"
              @click="resetField"
              v-if="caricato"
            >
              Reset
            </button>
            <button
              type="submit"
              class="badge bg-secondary rounded blueFit fs-6"
              :disabled="disableButton"
              v-if="caricato"
            >
              Registra pagamento
            </button>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { ref, getCurrentInstance, computed, watch } from "vue";

import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

import Datepicker from "vue3-date-time-picker";
import "vue3-date-time-picker/dist/main.css";
import { format, fromUTCtoISODate } from "@/composables/formatDate.js";

import { useStore } from "vuex";

import {
  approvaMovimentiMultiplaDaPagamento,
  getMovimentiImporto,
} from "@/requests/economato";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { Payments } from "@/store/enums/StoreEnums";

import { useNexiPayment } from "@/composables/useNexiPayment";
import { useFetchJSON, useFetchPayments } from "@/axios/dbManag";
export default {
  name: "pagamenti-movimenti-personale-add",
  emits: ["refreshList", "setSpinnerView"],
  props: {
    id_persona: {
      type: String,
      required: true,
    },
    pagaSelezionati: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    //TextInput,
    //SelectInput,
    Form,
    Datepicker,
    Field,
    ErrorMessage,
  },
  setup(props, { emit }) {
    const store = useStore();
    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const pagamento_tipo_uso_albo = computed(() =>
      store.getters.getStateFromName("pagamento_tipo_uso_albo")
    );

    const keys = ref("ptua");
    if (!pagamento_tipo_uso_albo.value) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const alertCampiObb = ref(false);
    const importo = ref(null);
    importo.value = 0;
    const id_Tipo_Pagamento = ref("00");
    const pagDatiAggiuntivi = ref(0);
    const dataPagamento = ref(null);
    const banca = ref("");
    const riferimento = ref("");
    //const stagione = ref(null);
    const caricato = ref(false);
    const stagione = computed(() => store.getters.stagioneSelected);

    const setPagamentoTipo = (e) => {
      if (
        e.target.selectedOptions[0]._value != null &&
        String(e.target.selectedOptions[0]._value) != ""
      ) {
        id_Tipo_Pagamento.value = e.target.selectedOptions[0]._value;
        if (
          pagDatiAggiuntivi.value == 1 &&
          parseInt(e.target.selectedOptions[0].id) == 0
        ) {
          dataPagamento.value = null;
          banca.value = "";
          riferimento.value = "";
        }
        pagDatiAggiuntivi.value = parseInt(e.target.selectedOptions[0].id);
      } else {
        id_Tipo_Pagamento.value = "00";
        pagDatiAggiuntivi.value = 1;
        dataPagamento.value = null;
        banca.value = "";
        riferimento.value = "";
      }
    };

    const schema = yup.object().shape({
      id_Tipo_Pagamento: yup
        .string()
        .test("notEmpty", "Obbligatorio", function (value) {
          if (value != "00" && value != "") {
            return true;
          } else {
            return false;
          }
        }),
      data_Competenza: yup.string().when("id_Tipo_Pagamento", {
        is: (id_Tipo_Pagamento) => parseInt(pagDatiAggiuntivi.value) === 1,
        then: yup.string().required("Obbligatorio").nullable(),
        otherwise: yup.string().nullable(),
      }),
      banca: yup.string().when("id_Tipo_Pagamento", {
        is: (id_Tipo_Pagamento) => parseInt(pagDatiAggiuntivi.value) === 1,
        then: yup.string().required("Obbligatorio").nullable(),
        otherwise: yup.string().nullable(),
      }),
      riferimento: yup.string().when("id_Tipo_Pagamento", {
        is: (id_Tipo_Pagamento) => parseInt(pagDatiAggiuntivi.value) === 1,
        then: yup.string().required("Obbligatorio").nullable(),
        otherwise: yup.string().nullable(),
      }),
    });

    const disableButton = ref(false);

    const { startPayment, paymentResult, pagamentoEffettuato } =
      useNexiPayment();
    watch(pagamentoEffettuato, (value) => {
      if (value) {
        emit("refreshList");
        document.getElementById("closeModalPagamentoMovimenti").click();
      }
    });

    const onSubmit = (values) => {
      alertCampiObb.value = false;
      Swal.fire({
        html:
          "Per confermare il pagamento di EUR " +
          String(Number(importo.value).toFixed(2)) +
          ", si prega di cliccare su <b>Conferma</b>.<br />Per annullare l'operazione cliccare su <b>Annulla</b>.",
        showCancelButton: true,
        confirmButtonText: "Conferma",
        cancelButtonText: "Annulla",
      }).then((result) => {
        if (result.isConfirmed) {
          disableButton.value = true;
          emit("setSpinnerView", true);
          values.arr_Movimenti = props.pagaSelezionati.join(",");
          values.descrizione = banca.value + " - " + riferimento.value;
          values.id_Persona = props.id_persona;
          values.anno = stagione.value;
          values.importo = importo.value;
          const responseAdd = approvaMovimentiMultiplaDaPagamento(values);
          responseAdd.then(async (value) => {
            if (value.data.payment_id) {
              // PAGAMENTI AXEPTA CHE QUI NON SERVONO
              // pagamento(value.data.payment_id);
              // return;
              const payload = ref({
                id_movimento: parseInt(value.data.id),
                id_societa: null,
                id_persona: props.id_persona,
                anno: stagione.value,
                importo: importo.value,
                return_url: window.location.href,
              });
              const { isFetching, error, data, execute } = useFetchPayments(
                "/v1/economato/carta-di-credito/nexi/inizializza"
              )
                .post(payload.value)
                .json();

              await execute();

              await startPayment(data.value);
            } else {
              if (value.data.statusCode == 200) {
                Swal.fire({
                  html: "Operazione completata",
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "OK",
                  customClass: {
                    confirmButton: "btn fw-bold btn-light-danger",
                  },
                });
                emit("refreshList");
                resetField();
                document.getElementById("closeModalPagamentoMovimenti").click();
              } else {
                const strMsg = ref(
                  value.data.message
                    ? value.data.message
                    : "Attenzione! Si è verificato un errore. Riprovare più tardi"
                );
                Swal.fire({
                  html: strMsg.value,
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "OK",
                  customClass: {
                    confirmButton: "btn fw-bold btn-light-danger",
                  },
                });
              }
            }

            emit("setSpinnerView", false);
            disableButton.value = false;
          });
        }
      });
    };

    const onInvalidSubmit = () => {
      alertCampiObb.value = true;
    };

    const resetField = () => {
      alertCampiObb.value = false;
      id_Tipo_Pagamento.value = "00";
      pagDatiAggiuntivi.value = 0;
      dataPagamento.value = null;
      banca.value = "";
      riferimento.value = "";
    };

    watch(
      () => props.pagaSelezionati,
      (value) => {
        resetField();
        caricato.value = false;
        if (props.pagaSelezionati.length > 0) {
          getMovimentiImporto(props.pagaSelezionati.join(",")).then((res) => {
            importo.value = parseFloat(res.results[0].totale);
            caricato.value = true;
          });
        }
      }
    );

    const axeptaClient = window.axeptaClient;
    const pagamento = (payment_id) => {
      axeptaClient.proceedToPayment(`${payment_id}`);
    };

    return {
      Payments,
      onSubmit,
      onInvalidSubmit,
      schema,
      pagamento_tipo_uso_albo,
      id_Tipo_Pagamento,
      setPagamentoTipo,
      pagDatiAggiuntivi,
      format,
      fromUTCtoISODate,
      dataPagamento,
      banca,
      riferimento,
      alertCampiObb,
      resetField,
      stagione,
      disableButton,
      caricato,
      importo,
    };
  },
  computed: {
    pagamento_tipo_uso_albo_filtrato_per_importo() {
      return this.pagamento_tipo_uso_albo.filter((item) => {
        if (this.importo < this.Payments.MIN_PER_PAGAMENTI_BONIFICO) {
          return (
            item.id === this.Payments.PAGAMENTO_CDC ||
            item.id === this.Payments.PAGAMENTO_MAV_LIGHT
          );
        } else {
          return (
            item.id === this.Payments.PAGAMENTO_CDC ||
            item.id === this.Payments.PAGAMENTO_MAV_LIGHT ||
            item.id === this.Payments.PAGAMENTO_BONIFICO
          );
        }
      });
    },
  },
};
</script>

<style scoped>
.modal-body {
  padding: 0;
}

@media (max-width: 1200px) {
  .modal-dialog {
    max-width: 55%;
  }
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 65%;
  }
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: translate(5%, -50%);
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 0px;
  width: 0px;
}
</style>
