// src/composables/useNexiPayment.js

import { ref } from "vue";
import { CodiceEsito } from "@/store/enums/StoreEnums";
import { alertFailed, alertSuccess } from "./swAlert";

export function useNexiPayment() {
  const isProd = process.env.VUE_APP_IS_PROD;
  // const secretKey = process.env.VUE_APP_NEXI_SECRET_KEY;

  const paymentResult = ref(null);
  const pagamentoEffettuato = ref(false);

  const startPayment = async (data) => {
    const {
      alias,
      importo,
      divisa,
      codTrans,
      mac,
      urlpost,
      note1,
      nome,
      cognome,
      mail,
      note2,
    } = data;
    try {
      // Inizializzazione dello SDK
      window.XPay.init();
      // LE CONFIGURAZIONI VENGONO TRASMESSE TUTTE LATO SERVER
      const timestamp = Date.now() * 1000;

      const config = {
        baseConfig: {
          apiKey: alias,
          enviroment:
            isProd == "1"
              ? window.XPay.Environments.PROD
              : window.XPay.Environments.INTEG,
        },
        paymentParams: {
          amount: importo,
          transactionId: codTrans,
          currency: divisa,
          timeStamp: timestamp,
          mac: mac,
          urlPost: urlpost,
        },
        customParams: {
          Note1: note1,
          nome: nome,
          cognome: cognome,
          mail: mail,
          Note2: note2,
        },
        language: window.XPay.LANGUAGE.ITA,
      };

      // Configurazione lightbox
      window.XPay.initLightbox(config);

      // Avvio del pagamento
      window.XPay.openLightbox();

      // Gestione del risultato del pagamento
      window.addEventListener("XPay_Payment_Result", (event) => {
        paymentResult.value = event.detail;
        if (paymentResult.value.codiceEsito == CodiceEsito.APPROVED) {
          pagamentoEffettuato.value = true;
          alertSuccess("Pagamento effettuato con successo!");
        } else {
          pagamentoEffettuato.value = false;
          if (paymentResult.value.esito == "ANNULLO") {
            alertFailed("Pagamento annullato.");
          } else {
            alertFailed(
              `Pagamento non effettuato. Riprovare più tardi <br><br> ${
                paymentResult.value.codiceEsito
                  ? `<small>Codice Errore: ${paymentResult.value.codiceEsito} <br> (${paymentResult.value.messaggio})</small>`
                  : ""
              } `
            );
          }
        }
      });
    } catch (e) {
      paymentResult.value = e;
      console.log(e);
    }
  };

  return {
    paymentResult,
    startPayment,
    pagamentoEffettuato,
  };
}
