<template>
  <div>
    <div class="card shadow">
      <div class="pt-4 pb-4 pe-4 ps-4">
        <div class="row">
          <div class="col-sm-3">
            <SelectInput
              :options="stagioni"
              name="stagioni"
              placeholder="Stagione"
              :value="stagione"
              @changeSelect="setStagione"
            />
          </div>
          <div class="text-end col-md-9">
            <span
              type="button"
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="badge rounded-pill bg-light text-gray-600 ms-4"
              @click="resetStagione()"
            >
              <i class="bi bi-arrow-clockwise fs-6 text-gray-600"> </i
            ></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, watch } from "vue";
import { useStore } from "vuex";

import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";

export default defineComponent({
  name: "filtriEconomatoPersonale",
  emits: ["getEconomatoList"],
  props: {
    campiLocked: {
      type: String,
      required: true,
    },
  },
  components: {
    SelectInput,
  },
  setup(props, { emit }) {
    const store = useStore();

    const stagione = computed(() => store.getters.stagioneSelected);
    const defaultStagione = computed(() => store.getters.defaultStagione);
    const stagioneCorrente = computed(() => store.getters.stagioneCorrente);

    const checkStagioneCorrente = () => {
      if (defaultStagione.value == new Date().getFullYear()) {
        store.commit("setStagioneCorrente", true);
      } else store.commit("setStagioneCorrente", false);
    };

    const setStagione = (event) => {
      store.commit("setStagioneSelected", parseInt(event));
      store.commit("setStagioneDefault", parseInt(event));
      store.commit("setStagione", parseInt(event));
      checkStagioneCorrente();
      if (event != null) store.commit("setViewPagaSelezionati", true);
      else store.commit("setViewPagaSelezionati", false);
    };

    const resetStagione = () => {
      store.commit("setStagioneSelected", new Date().getFullYear());
      store.commit("setStagioneDefault", new Date().getFullYear());
      store.commit("setStagione", new Date().getFullYear());
      store.commit("resetRowsToSkipAPSNM");
      checkStagioneCorrente();
    };

    watch(stagione, () => {
      emit("getEconomatoList");
    });

    return {
      setStagione,
      stagioni: computed(() => store.getters.stagioni),
      defaultStagione,
      stagioneCorrente,
      stagione,
      resetStagione,
      checkStagioneCorrente,
    };
  },
});
</script>
