<template>
  <div>
    <loading
      :active="isLoading"
      :is-full-page="fullPage"
      :z-index="1100"
    ></loading>
    <div class="row mt-5 justify-content-between">
      <div class="col-sm-4" v-if="isEnabled('fnEsportaEconomatoInsegnante')">
        <button
          type="button"
          class="btn btn-light text-gray-700 fs-6"
          @click="esportaLista()"
        >
          <i class="bi bi-download fs-6 text-gray-700 me-1"></i>esporta elenco
          movimenti approvati
        </button>
      </div>
      <div
        class="col-sm-4"
        :class="isEnabled('fnEsportaEconomatoInsegnante') ? 'text-center' : ''"
      >
        <div
          class="btn btn-light text-gray-700 fs-6 text-center"
          v-if="!fromInsegnante"
        >
          <input
            class="form-check-input me-2"
            type="checkbox"
            @click="setTutteLeStagioniEconomatoSnm"
            :checked="tutteStagioniEconomatoSnm"
          />visualizza tutte le stagioni
        </div>
      </div>
      <div class="col-sm-4 text-end">
        <div class="row justify-content-left pt-1">
          <div class="fs-5 row">
            <div>
              <button
                v-if="isEnabled('fnEconomatoPersonaleAddMovimento')"
                type="button"
                class="badge bg-secondary rounded blueFit me-2 mb-1"
                data-bs-toggle="modal"
                data-bs-target="#modal_add_movimento_personale"
              >
                introduci movimento
              </button>
              <button
                type="button"
                class="badge bg-secondary rounded blueFit mb-1"
                data-bs-toggle="modal"
                data-bs-target="#modal_add_pagamento_movimenti_personale"
                v-if="pagaSelezionati.length && viewPagaSelezionati"
                :disabled="disableButton"
                @click="resetModalAddPagamentoMovimenti()"
              >
                Paga selezionati
              </button>
              <button
                type="button"
                class="badge bg-secondary rounded blueFit mb-1"
                v-if="!pagaSelezionati.length && viewPagaSelezionati"
                @click="showPopupSelect()"
              >
                Paga selezionati
              </button>
            </div>
          </div>
        </div>
      </div>
      <AddMovimentoPersonale
        @refreshList="refreshList"
        @setSpinnerView="setSpinnerView"
        ref="modalAddMovimento"
        :id_persona="id_persona"
      />
      <AddPagamentoMovimentiPersonale
        @refreshList="refreshList"
        @setSpinnerView="setSpinnerView"
        ref="modalAddPagamentoMovimenti"
        :id_persona="id_persona"
        :pagaSelezionati="pagaSelezionati"
        @modalClosed="showModal = false"
      />
    </div>
    <br />
    <div class="mx-auto my-auto text-center" v-if="!loaded">
      <div class="spinner-border text-gray-600" role="status"></div>
      <span class="text-gray-600 ps-3 fs-2">Loading</span>
    </div>
    <div
      v-else-if="
        economato_personale_list &&
        economato_personale_list.length === 0 &&
        loaded &&
        (status === 204 || status === 200)
      "
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Nessun risultato.
      </span>
    </div>
    <div
      v-else-if="status != 200 && status != 204"
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Si è verificato un errore. Riprovare più tardi.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
      >
        <router-link v-if="isEnabled('fnLinkBachecaCompleta')" to="/bacheca">
          <i class="bi bi-house fs-2 text-gray-800"> </i
        ></router-link>
        <a
          v-else-if="!hasBacheca"
          :href="redirectInsegnanti"
          class="btn text-hover-primary text-gray-600 fs-5 text-end p-0 m-0"
        >
          <i class="bi bi-house fs-2 text-gray-800"> </i>
        </a>
        <router-link v-else to="/bacheca-public">
          <i class="bi bi-house fs-2 text-gray-800"> </i
        ></router-link>
      </span>
    </div>
    <div v-else>
      <Datatable
        :table-header="tableHeader"
        :table-data="economato_personale_list"
        :total="record"
        :current-page="currentPage"
        :rows-per-page="fetchRows"
        :sortLabel="sortColumn"
        :order="sortOrder"
        @sort="setSortOrderColumn"
        @items-per-page-change="setFetchRowsEconomatoSnm"
        @current-change="setCurrentPagEconomatoSnm"
        @checkboxClicked="setAllMovimenti"
        :checkedAll="checkAll && viewPagaSelezionati"
      >
        <template v-slot:cell-checkbox="{ row: data }"
          ><input
            type="checkbox"
            class="movimentiSelected"
            name="movimentiSelected"
            v-model="data.selected"
            :value="data.id_movimento"
            v-if="data.pagabile && viewPagaSelezionati"
            @click="setSingoloMovimento(data)"
        /></template>
        <template v-slot:cell-numero="{ row: data }">{{
          data.numero
        }}</template>
        <template v-slot:cell-tipo="{ row: data }">{{ data.tipo }}</template>
        <template v-slot:cell-causale="{ row: data }">{{
          data.causale
        }}</template>
        <template v-slot:cell-descrizione="{ row: data }"
          ><div class="tab-long-xl">{{ data.descrizione }}</div></template
        >
        <template v-slot:cell-importo="{ row: data }"
          ><div class="text-end">
            {{ data.importo.toFixed(2) }}
          </div></template
        >
        <template v-slot:cell-data_registrazione="{ row: data }"
          ><div class="text-center">
            {{ data.data_registrazione }}
          </div></template
        >
        <template v-slot:cell-data_approvazione="{ row: data }"
          ><div class="text-center">
            {{ data.data_approvazione }}
          </div></template
        >
        <template v-slot:cell-options="{ row: data }">
          <button
            v-if="
              (data.codice_transazione || data.puo_convertire_mav) &&
              !data.convertito
            "
            class="btn btn-sm dropdown p-1"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="bi bi-three-dots-vertical text-dark fs-4"></i>
          </button>
          <ul class="dropdown-menu">
            <a
              href="#"
              @click="downloadMav(data.codice_transazione)"
              v-if="data.codice_transazione && !data.convertito"
            >
              <li role="presentation" class="navi-item">
                <div class="b-dropdown-text text-start p-2">
                  <div class="navi-link text-dark">
                    <i class="bi bi-download text-dark me-2"></i>Scarica MAV
                  </div>
                </div>
              </li></a
            >
            <a
              href="#"
              @click="
                importoSelected = data.importo;
                pagamentoSelected = data.id_movimento;
              "
              data-bs-toggle="modal"
              data-bs-target="#modal_conversione_mav"
              v-if="data.puo_convertire_mav"
            >
              <li role="presentation" class="navi-item">
                <div class="b-dropdown-text text-start p-2">
                  <div class="navi-link text-dark">
                    <i class="bi bi-arrow-left-right text-dark me-2"></i
                    >Converti MAV
                  </div>
                </div>
              </li>
            </a>
          </ul>
        </template>
      </Datatable>
    </div>
    <ConvertiMav
      @refreshList="refreshList"
      @setSpinnerView="setSpinnerView"
      :id_persona="id_persona"
      :importo="importoSelected"
      :id_pagamento="pagamentoSelected"
    />
  </div>
</template>

<script>
import Datatable from "../../../kt-datatable/KTDatatable.vue";
import AddMovimentoPersonale from "./AddMovimentoPersonale.vue";
import AddPagamentoMovimentiPersonale from "./AddPagamentoMovimentiPersonale.vue";
import ConvertiMav from "./ConvertiMav.vue";
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import { pdfMAV } from "@/requests/downloadFiles";
// componente spinner
import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import { alertFailed } from "@/composables/swAlert";
import isEnabled from "@/composables/isEnabled.js";
import { useRedirectInsegnanti } from "@/composables/redirectInsegnanti";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default {
  name: "table-economato-personale",
  components: {
    Datatable,
    AddMovimentoPersonale,
    AddPagamentoMovimentiPersonale,
    Loading,
    ConvertiMav,
  },
  emits: ["getEconomatoList", "resetFilters"],
  props: {
    id_persona: {
      type: Number,
      required: true,
    },
    fromInsegnante: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const tableHeader = ref([
      {
        name: "Numero",
        key: "numero",
      },
      {
        name: "Tipo",
        key: "tipo",
      },
      {
        name: "Causale",
        key: "causale",
      },
      {
        name: "Descrizione",
        key: "descrizione",
      },
      {
        name: "Importo",
        key: "importo",
        align: "end",
      },
      {
        name: "Data registrazione",
        key: "data_registrazione",
        align: "center",
      },
      {
        name: "Data approvazione",
        key: "data_approvazione",
        align: "center",
      },
      {
        key: "options",
        sortable: false,
      },
    ]);
    const tutteStagioniEconomatoSnm = computed(
      () => store.getters.tutteStagioniEconomatoSnm
    );
    const addCheckboxToTable = () => {
      tableHeader.value.unshift({
        key: "checkbox",
        sortable: false,
      });
    };

    const setFetchRowsEconomatoSnm = (e) => {
      checkAll.value = false;
      store.commit("setFetchRowsEconomatoSnm", e);
      emit("getEconomatoList");
    };
    const setCurrentPagEconomatoSnm = (page) => {
      checkAll.value = false;
      store.commit("setCurrentPagEconomatoSnm", page);
      emit("getEconomatoList");
    };
    const setSortOrderColumn = ({ columnName, order }) => {
      checkAll.value = false;
      store.commit("setSortColumnEconomatoSnm", columnName);
      store.commit("setSortOrderEconomatoSnm", order);
      emit("getEconomatoList");
    };

    const importoSelezionati = ref(0);
    const checkAll = ref(false);

    const refreshList = () => {
      checkAll.value = false;
      emit("getEconomatoList");
    };

    const pagaSelezionati = ref([]);
    const showModal = ref(false);

    const setPagaSelezionati = () => {
      // showModal.value = false;
      // let cboxes = document.getElementsByName("movimentiSelected");
      // let len = cboxes.length;
      pagaSelezionati.value = [];
      economato_personale_list.value.forEach((element) => {
        if (element.selected) {
          pagaSelezionati.value.push(element.id_movimento.toString());
        }
      });
      // for (var i = 0; i < len; i++) {
      //
      //   if (cboxes[i].checked) {
      //     pagaSelezionati.value.push(cboxes[i].value);
      //   }
      // }
      if (pagaSelezionati.value.length == 0) {
        // showModal.value = false;
      } else {
        showModal.value = true;
      }
    };

    const showPopupSelect = () => {
      if (pagaSelezionati.value.length == 0) {
        alertFailed(
          "Per procedere è necessario selezionare uno o più movimenti",
          "modal_add_pagamento_movimenti_personale"
        );
      }
    };

    const isLoading = ref(false);
    const fullPage = ref(true);

    const setSpinnerView = (value) => {
      isLoading.value = value;
    };

    const economato_personale_list = computed(() =>
      store.getters.getStateFromName("resultseconomato_snm_list")
    );

    const setAllMovimenti = () => {
      checkAll.value = !checkAll.value;
      importoSelezionati.value = 0;
      economato_personale_list.value.forEach((element) => {
        if (element.pagabile) {
          checkAll.value
            ? (element.selected = true)
            : (element.selected = false);
          if (element.selected) {
            importoSelezionati.value += element.importo;
          }
        }
      });
      setPagaSelezionati();
    };

    const setSingoloMovimento = (movimento) => {
      checkAll.value = false;
      movimento.selected = !movimento.selected;
      importoSelezionati.value = 0;
      economato_personale_list.value.forEach((element) => {
        if (element.pagabile) {
          if (element.selected) {
            importoSelezionati.value += element.importo;
          }
        }
      });
      setPagaSelezionati();
    };

    const downloadMav = async (codice_transazione) => {
      isLoading.value = true;
      await pdfMAV(codice_transazione);
      isLoading.value = false;
    };

    const { pagesLength, redirectInsegnanti, menuLoaded, hasBacheca } =
      useRedirectInsegnanti();

    const setTutteLeStagioniEconomatoSnm = () => {
      store.commit("setTutteLeStagioniEconomatoSnm");
      emit("getEconomatoList");
    };

    const esportaLista = () => {
      Swal.fire({
        html: "Funzionalità presto disponibile",
        icon: "warning",
        buttonsStyling: false,
        confirmButtonText: "Chiudi",
        customClass: {
          confirmButton: "btn fw-bold btn-light-warning",
        },
      });
    };

    const pagamentoSelected = ref(null);
    const importoSelected = ref(null);

    const viewPagaSelezionati = computed(
      () => store.getters.viewPagaSelezionati
    );

    const setViewPagaSelezionati = () => {
      if (tutteStagioniEconomatoSnm.value)
        store.commit("setViewPagaSelezionati", false);
      else store.commit("setViewPagaSelezionati", true);
    };

    watch(tutteStagioniEconomatoSnm, () => {
      setViewPagaSelezionati();
    });

    watch(
      (viewPagaSelezionati.value,
      () => {
        if (viewPagaSelezionati.value == false) {
          tableHeader.value.shift();
        } else {
          addCheckboxToTable();
        }
      })
    );

    return {
      tableHeader,
      setFetchRowsEconomatoSnm,
      setCurrentPagEconomatoSnm,
      setSortOrderColumn,
      economato_personale_list: computed(() =>
        store.getters.getStateFromName("resultseconomato_snm_list")
      ),
      loaded: computed(() =>
        store.getters.getStateFromName("loadedeconomato_snm_list")
      ),
      record: computed(() =>
        store.getters.getStateFromName("recordeconomato_snm_list")
      ),
      status: computed(() =>
        store.getters.getStateFromName("statuseconomato_snm_list")
      ),
      currentPage: computed(() => store.getters.currentPageEconomatoSnm),
      rowsToSkip: computed(() => store.getters.rowsToSkipEconomatoSnm),
      fetchRows: computed(() => store.getters.fetchRowsEconomatoSnm),
      sortColumn: computed(() => store.getters.sortColumnEconomatoSnm),
      sortOrder: computed(() => store.getters.sortOrderEconomatoSnm),
      refreshList,
      isLoading,
      fullPage,
      setSpinnerView,
      checkAll,
      importoSelezionati,
      pagaSelezionati,
      setPagaSelezionati,
      showPopupSelect,
      showModal,
      setAllMovimenti,
      setSingoloMovimento,
      isEnabled,
      downloadMav,
      pagamentoSelected,
      importoSelected,

      pagesLength,
      redirectInsegnanti,
      menuLoaded,
      hasBacheca,
      esportaLista,

      tutteStagioniEconomatoSnm,
      setTutteLeStagioniEconomatoSnm,

      viewPagaSelezionati,
      setViewPagaSelezionati,
    };
  },
  methods: {
    resetModalAddMovimento() {
      this.$refs.modalAddMovimento.resetField();
    },
    resetModalAddPagamentoMovimenti() {
      this.$refs.modalAddPagamentoMovimenti.resetField();
    },
  },
};
</script>

<style scoped></style>
