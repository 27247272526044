<template>
  <div class="modal fade" tabindex="-10" id="modal_conversione_mav">
    <div class="modal-dialog">
      <Form
        @submit="onSubmit"
        :validation-schema="schema"
        @invalid-submit="onInvalidSubmit"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Converti MAV</h5>
            <!--begin::Close-->
            <div
              class="btn btn-icon btn-sm btn-active-secondary"
              style="border: 0; !important"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="bi bi-x-lg"> </i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body">
            <div class="card-body p-6">
              <form autocomplete="off">
                <div
                  class="alert alert-danger p-6 rounded text-start mb-5 mx-5"
                  role="alert"
                  v-if="alertCampiObb"
                >
                  Compila i campi obbligatori
                </div>
                <div class="ps-5 pe-5">
                  <div class="row pb-4">
                    <div class="col-md-4">
                      <label class="fw-bold">Tipo pagamento*</label>
                      <input
                        type="text"
                        value="Bonifico"
                        readonly
                        class="form-control"
                      />
                    </div>
                    <div class="col-md-3">
                      <label class="fw-bold">Importo*</label>
                      <div
                        class="input-group mb-0 input-group-sm"
                        v-if="importo"
                      >
                        <input
                          type="text"
                          :value="importo.toFixed(2)"
                          class="form-control"
                          readonly
                        />
                        <span class="input-group-text" style="height: 28px"
                          >€</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <label class="fw-bold">Banca / Agenzia*</label>
                      <Field
                        name="banca"
                        type="text"
                        v-model="banca"
                        class="form-control"
                        autocomplete="off"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="banca"
                      />
                    </div>
                    <div class="col-md-6">
                      <label class="fw-bold">Riferimenti*</label>
                      <Field
                        name="riferimento"
                        type="text"
                        class="form-control"
                        v-model="riferimento"
                        autocomplete="off"
                      />
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="riferimento"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="me-3 badge bg-light-danger rounded text-danger fs-6"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="closeModalConverti"
            >
              Annulla
            </button>
            <button
              type="submit"
              class="badge bg-secondary rounded blueFit fs-6"
              :disabled="disableButton"
            >
              Converti
            </button>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

import { convertiMav } from "@/requests/economato";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default {
  name: "pagamenti-movimenti-personale-add",
  emits: ["refreshList", "setSpinnerView"],
  props: {
    id_persona: {
      type: Number,
      required: true,
    },
    id_pagamento: {
      type: Number,
      required: true,
    },
    importo: {
      type: String,
      required: true,
    },
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  setup(props, { emit }) {
    const alertCampiObb = ref(false);

    const banca = ref("");
    const riferimento = ref("");

    const schema = yup.object().shape({
      banca: yup.string().required("Obbligatorio").nullable(),
      riferimento: yup.string().required("Obbligatorio").nullable(),
    });

    const disableButton = ref(false);

    const onSubmit = (values) => {
      alertCampiObb.value = false;
      Swal.fire({
        title: "Sei Sicuro?",
        html: "Procedendo il pagamento tramite MAV verrà convertito in Bonifico.",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Converti",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-success",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          disableButton.value = true;
          emit("setSpinnerView", true);
          values.descrizione = banca.value + " - " + riferimento.value;
          const responseAdd = convertiMav(
            props.id_persona,
            props.id_pagamento,
            values.descrizione
          );
          responseAdd.then((res) => {
            if (res.data.statusCode == 200) {
              Swal.fire({
                html: "Operazione completata",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "OK",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
              emit("refreshList");
              resetField();
              document.getElementById("closeModalConverti").click();
            } else {
              const strMsg = ref(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
              Swal.fire({
                html: strMsg.value,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "OK",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            }
            emit("setSpinnerView", false);
            disableButton.value = false;
          });
        }
      });
    };

    const onInvalidSubmit = () => {
      alertCampiObb.value = true;
    };

    const resetField = () => {
      alertCampiObb.value = false;
      banca.value = "";
      riferimento.value = "";
    };

    return {
      onSubmit,
      onInvalidSubmit,
      schema,
      banca,
      riferimento,
      alertCampiObb,
      resetField,
      disableButton,
    };
  },
};
</script>

<style scoped>
.modal-body {
  padding: 0;
}

@media (max-width: 1200px) {
  .modal-dialog {
    max-width: 55%;
  }
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 65%;
  }
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: translate(5%, -50%);
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 0px;
  width: 0px;
}
</style>
